/** @jsx jsx */
import { useEffect, useState, useRef } from "react"
import { jsx, Flex, Checkbox, Slider } from "theme-ui"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import Columns from "../components/columns"
import Tab from "../components/tab"
import Characters, { CharacterList } from "../components/characters"
import Result from "../components/@team-builder-result"
import UseLocalStorage from "../hooks/use-local-storage"

const Page = () => {
	const {t} = useTranslation()
		, storage = useRef(new UseLocalStorage(`team-builder`))
		, data = useRef(storage.current.get(`data`))
		, init = useRef({
			owned: data.current ? data.current.owned : ["Lumine", "Amber", "Kaeya", "Lisa", "Barbara", "Noelle"],
			wl8: data.current ? data.current.wl8 : false,
		})
		, [owned, setOwned] = useState(init.current.owned)
		, [fav, setFav] = useState(`None`)
		, [wl8, setWl8] = useState(init.current.wl8)
		, [num, setNum] = useState(1)
	useEffect(()=>{
		storage.current.set(`data`, {owned: owned, wl8: wl8,}, true)
	},[owned,wl8])
	return (<Layout title={t(`Team Builder`)}>
		<Columns>
			<div>
				<Flex sx={{justifyContent: `space-between`,}}>
					<div sx={{display: `inline-block`,}}>
						<label sx={{display: `flex`,}}>
							<Checkbox
								onChange={e => setWl8(e.target.checked)}
								defaultChecked={init.current.wl8}
							/>{t(`World Level 8`)}
						</label>
					</div>
					<div sx={{display: `inline-block`,}}>
						<label sx={{display: `flex`,}}>
							<Slider
								defaultValue={1} min={1} max={5}
								sx={{mr: 1, width: `5em`,}}
								onChange={e => setNum(e.target.value)}
							/>{t(`Display`)} : {num}
						</label>
					</div>
				</Flex>
				<Tab
					name="result"
					labels={[t(`Exploration Team`),t(`Spiral Abyss`)]}
				>
					<Result owned={[fav, ...owned]} fav={fav} wl8={wl8} num={num} t={t} />
				</Tab>
			</div>
			<div>
				<Characters
					label="Favorite Character"
					name="favorite-character"
					init="None"
					listName="teamBuilder"
					unselect={true}
					t={t}
					onChange={setFav}
				/>
				<div sx={{mt:4}}>{t(`Owned Characters`)}</div>
				<CharacterList
					name="owned-characters"
					init={init.current.owned}
					listName="teamBuilder"
					t={t}
					onChange={setOwned}
				/>
			</div>
		</Columns>
	</Layout>)
}
export default Page

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common","characters","team-builder"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
